'use strict';

var slick = require('slick-carousel-browserify');

$(function () {
  //Slick slider
  slick($('div.slider'), {
    dots: false,
    fade: true,
    prevArrow: $('.prev-slider'),
    nextArrow: $('.next-slider'),
    speed: 1200,
    autoPlay: true,
    autoplay: true
  });

  //Fixed header
  $(window).scroll(function () {
    var header = $('.header-fix');

    if ($(window).scrollTop() >= 250) {
      header.addClass('visible');
      header.addClass('slideInDown');
    } else {
      header.removeClass('visible');
      header.removeClass('slideInDown');
    }
  });

  window.FontAwesomeConfig = {
    searchPseudoElements: true
  };

  $('.active-mobile > a').click(function () {
    $('nav.mobile').addClass('activo');
  });
});